import state from './moduleBoletoState.js'
import mutations from './moduleBoletoMutations.js'
import actions from './moduleBoletoActions.js'
import getters from './moduleBoletoGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
