<template>
  <div id="page-registrations">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Boletos" icon-pack="feather" icon="icon-clipboard">
            <div class="tab-text">
              <boletos-tab class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Remessas" icon-pack="feather" icon="icon-grid">
            <div class="tab-text">
              <remessas-tab class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import BoletosTab   from './tabs/Boletos/Boletos.vue'
import RemessasTab  from './tabs/Remessas/Remessas.vue'

// Store Module

export default {
  components: {
    BoletosTab,
    RemessasTab
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>
<style lang="scss">
#page-student-edit {
  .vs-tabs--content {
    padding: 0 10px 23px !important;
  }
}
</style>
