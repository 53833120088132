var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tab-remessas" } },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "w-full mt-2",
              attrs: {
                type: "date",
                label: "Período Inicial",
                "data-vv-as": "Período Inicial",
                name: "firstPeriod",
              },
              on: { change: _vm.checkPeriod },
              model: {
                value: _vm.firstPeriod,
                callback: function ($$v) {
                  _vm.firstPeriod = $$v
                },
                expression: "firstPeriod",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("firstPeriod"),
                    expression: "errors.has('firstPeriod')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("firstPeriod")))]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.periodError,
                    expression: "periodError",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.periodErrorMessage))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "w-full mt-2",
              attrs: {
                type: "date",
                label: "Período Final",
                "data-vv-as": "Período Final",
                name: "lastPeriod",
              },
              on: { change: _vm.checkPeriod },
              model: {
                value: _vm.lastPeriod,
                callback: function ($$v) {
                  _vm.lastPeriod = $$v
                },
                expression: "lastPeriod",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("lastPeriod"),
                    expression: "errors.has('lastPeriod')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("lastPeriod")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Filtrar por"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                placeholder: "Selecione",
                clearable: false,
                options: [
                  { value: "PROCESSING", label: "Data de Processamento" },
                  { value: "DUE_DATE", label: "Data de Vencimento" },
                ],
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.filterBy,
                callback: function ($$v) {
                  _vm.filterBy = $$v
                },
                expression: "filterBy",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col md:w-full w-full" }, [
          _c("label", { staticClass: "vs-input--label" }, [_vm._v("Aluno(s)")]),
          _c(
            "div",
            { staticClass: "mt-0 flex flex-wrap items-center justify" },
            [
              _c("v-select", {
                staticClass: "w-90__",
                attrs: {
                  multiple: "",
                  clearable: false,
                  filterable: true,
                  reduce: (option) => option.value,
                  options: _vm.studentOptions,
                  placeholder: "Todos. Digite CPF ou nome do aluno...",
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                },
                on: { search: _vm.debouncedGetSearchStudent },
                scopedSlots: _vm._u([
                  {
                    key: "no-options",
                    fn: function ({}) {
                      return [_vm._v(" Nenhum resultado encontrado. ")]
                    },
                  },
                ]),
                model: {
                  value: _vm.students,
                  callback: function ($$v) {
                    _vm.students = $$v
                  },
                  expression: "students",
                },
              }),
              _c("feather-icon", {
                staticClass: "ml-auto inline-flex rounded-full",
                attrs: {
                  title: "Limpar seleção",
                  icon: "MinusCircleIcon",
                  svgClasses: "w-8 h-8 cursor-pointer hover:text-primary",
                },
                on: { click: _vm.clearSelectedStudents },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mt-2",
                  attrs: { disabled: !_vm.validateForm || _vm.periodError },
                  on: { click: _vm.generateRemessas },
                },
                [_vm._v("Gerar Arquivo de Remessa")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "vs-popup",
        {
          attrs: { title: "Download Remessa", active: _vm.remessaPopup },
          on: {
            "update:active": function ($event) {
              _vm.remessaPopup = $event
            },
          },
        },
        _vm._l(_vm.remessas, function (remessa, index) {
          return _c(
            "vs-button",
            {
              key: index,
              staticClass: "w-full",
              attrs: { type: "border" },
              on: {
                click: function ($event) {
                  return _vm.download(index)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(remessa.bank) + "\n      ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }