<template>
  <div id="tab-boletos">
    <return-modal :show="popupReturn" title="Boletos no arquivo de retorno"
      :preProcessingResponse = "preProcessingResponse"
      @action="refreshGrid(false)"
      @cancel="popupReturn = false"></return-modal>

    <details-modal :show="popupDetailsBoleto" :title="`Detalhes do Boleto ${boleto.nosso_numero}`"
      @action="refreshInfiniteCacheGrid(false)"
      @cancel="popupDetailsBoleto = false"></details-modal>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <vs-input class="sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca por nosso nº ou nº do documento" />

        <input id="fileUpload" @change="onFileSelected" type="file" hidden>
        <vs-button @click="chooseFile" icon-pack="feather" icon="icon-file" class="shadow-md mb-4 md:mb-0">Processar Retorno...</vs-button>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
const returnModal = () => import('./returnModal.vue')
const detailsModal = () => import('./detailsModal.vue')

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererActions  from './cell-renderer/CellRendererActions.vue'
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'

import moduleBoleto from '@/store/boleto/moduleBoleto.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererDateTime,
    returnModal,
    detailsModal
  },
  data () {
    return {
      returnFile: null,
      preProcessingResponse: {
        boletos: []
      },

      popupReturn: false,
      popupDetailsBoleto: false,

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Nº Documento',
          field: 'transaction_cfc_id',
          width: 160
        },
        {
          headerName: 'Nosso Nº',
          field: 'nosso_numero',
          width: 140
        },
        {
          headerName: 'Dt. Processamento',
          field: 'data_processamento',
          width: 180,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Dt. Vcmto.',
          field: 'data_vencimento',
          width: 180,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Status',
          field: 'status', //nullable colocarei a ocorrencia aqui '1': 'Gerado', '06': 'Liquidado'
          valueFormatter: params => { return params.value && (params.value === '1' ? 'Gerado' : params.value === '06' ? 'Liquidado' :  'Ocorrência desconhecida') },
          width: 150
        },
        {
          headerName: 'Dt. Crédito',
          field: 'data_credito',
          width: 180,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Identificação',
          field: 'config_boleto.identification',
          width: 150
        },
        {
          headerName: 'Pagador',
          field: 'transaction_cfc',
          valueFormatter: params => { return params.value ? params.value.student.name : 'Tansação excluída ou inexistente.' },
          width: 250
        },
        {
          headerName: 'Valor',
          field: 'valor',
          valueFormatter: params => { return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' },
          width: 140
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            edit: this.edit, // usado para passar parametros para dentro o componente renderizado no ag grid
            delete: this.confirmDelete // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],
      components: {
        CellRendererActions,
        CellRendererDateTime
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    boleto () {
      return this.$store.state.boleto.boleto
    },
    allData () {
      return this.$store.state.boleto.boletosGrid
    }
  },
  methods: {
    edit (data) {
      this.$store.commit('boleto/SET', data.data)
      this.popupDetailsBoleto = true
    },
    confirmDelete (data) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_boleto')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$store.commit('boleto/SET', data.data)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir o Boleto \n "${data.data.nosso_numero}"?. A parcela será mantida no financeiro.`,
        accept: this.delete,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete () {
      this.$store.dispatch('boleto/delete', this.boleto.id)
        .then(()   => {
          this.refreshGrid()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },

    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },
    chooseFile () {
      document.getElementById('fileUpload').click()
    },
    async onFileSelected (event) {
      if (!event.target.files.length) return
      this.returnFile = event.target.files[0]

      try {
        this.$vs.loading()
        const fd = new FormData()
        fd.append('return_file', this.returnFile, this.returnFile.name)
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/return`, fd)

        this.preProcessingResponse = resp.data
        this.popupReturn = true

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }

      // const self = this
      // const reader = new FileReader()
      // reader.readAsDataURL(file)
      // reader.onload = function () {
      //   self.company.logo = reader.result
      // }
      // reader.onerror = function (error) {
      //   console.log('Error: ', error)
      // }
    },

    handleReturn () {
      this.popupReturn = true
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('boleto/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    if (!moduleBoleto.isRegistered) {
      this.$store.registerModule('boleto', moduleBoleto)
      moduleBoleto.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>

<style>

</style>
