import axios from '@/axios.js'

export default {
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/boletos`, payload)
        .then((response) => {
          commit('SET_BOLETOS_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/boleto/${payload}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
