import Vue from 'vue'

export default {
  SET (state, payload) {
    state.boleto = Object.assign({}, state.boleto, payload) //precisa ser assim para manter a reatividade
  },
  SET_BOLETOS (state, payload) {
    state.boletos = payload
  },
  SET_BOLETOS_GRID (state, payload) {
    state.boletosGrid = payload
  },
  STORE (state, payload) {
    state.boletos.push(payload) //precisa ser assim para manter a reatividade
    state.boletos.sort((a, b) => { return a.trading_name.localeCompare(b.trading_name) }) // Ordena por nome

    state.boletosGrid.data.push(payload)
    state.boletosGrid.data.sort((a, b) => { return a.trading_name.localeCompare(b.trading_name) }) // Ordena por nome
    state.boletosGrid.total = state.boletosGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.boletos.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.boletos, idx, payload)

    const idx1 = state.boletosGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.boletosGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.boletos.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.boletos.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.boletosGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.boletosGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.boletosGrid.total = state.boletosGrid.total - 1
    }
  }
}
