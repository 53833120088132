<template>
  <div id="tab-remessas">
    <!-- AO UTILIZAR A CLASSE class="vx-card p-2 TEMOS UM EFEITO DE CARD -->
    <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full">
        <vs-input
          type="date"
          class="w-full mt-2"
          label="Período Inicial"
          v-model="firstPeriod"
          @change="checkPeriod"
          data-vv-as="Período Inicial"
          v-validate.initial="'required'"
          name="firstPeriod"
        />
        <span class="text-danger text-sm" v-show="errors.has('firstPeriod')">{{
          errors.first('firstPeriod')
        }}</span>
        <span class="text-danger text-sm" v-show="periodError">{{
          periodErrorMessage
        }}</span>
      </div>
      <div class="vx-col md:w-1/3 w-full">
        <vs-input
          type="date"
          class="w-full mt-2"
          label="Período Final"
          v-model="lastPeriod"
          @change="checkPeriod"
          data-vv-as="Período Final"
          v-validate.initial="'required'"
          name="lastPeriod"
        />
        <span class="text-danger text-sm" v-show="errors.has('lastPeriod')">{{
          errors.first('lastPeriod')
        }}</span>
      </div>
      <div class="vx-col md:w-1/3 w-full mt-2">
        <label class="vs-input--label">Filtrar por</label>
        <v-select
          v-model="filterBy"
          :reduce="(option) => option.value"
          placeholder="Selecione"
          :clearable="false"
          :options="[
            { value: 'PROCESSING', label: 'Data de Processamento' },
            { value: 'DUE_DATE', label: 'Data de Vencimento' }
          ]"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-full w-full">
        <label class="vs-input--label">Aluno(s)</label>
        <div class="mt-0 flex flex-wrap items-center justify">
          <v-select
            class="w-90__"
            v-model="students"
            @search="debouncedGetSearchStudent"
            multiple
            :clearable="false"
            :filterable="true"
            :reduce="(option) => option.value"
            :options="studentOptions"
            placeholder="Todos. Digite CPF ou nome do aluno..."
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
          <feather-icon
            @click="clearSelectedStudents"
            title="Limpar seleção"
            icon="MinusCircleIcon"
            class="ml-auto inline-flex rounded-full"
            svgClasses="w-8 h-8 cursor-pointer hover:text-primary"
          ></feather-icon>
        </div>
      </div>
    </div>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button
            class="ml-auto mt-2"
            @click="generateRemessas"
            :disabled="!validateForm || periodError"
            >Gerar Arquivo de Remessa</vs-button
          >
        </div>
      </div>
    </div>
    <vs-popup title="Download Remessa" :active.sync="remessaPopup">
        <vs-button  @click="download(index)" v-for="(remessa, index) in remessas"  class="w-full" type="border" :key="index">
          {{remessa.bank}}
        </vs-button>
    </vs-popup>
  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      firstPeriod: '',
      lastPeriod: '',
      filterBy: 'PROCESSING',
      students: [],
      studentOptions: [],
      periodErrorMessage: 'O período é obrigatório.',
      periodError: false,
      remessaPopup: false,
      remessas: []
    }
  },
  computed: {
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    validateForm () {
      return !this.errors.any()
    }
  },
  methods: {
    download (idx) {
      const blob = new Blob([this.remessas[idx].remessa], { type: 'text/plain' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.remessas[idx].filename
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async generateRemessas () {
      try {
        this.$vs.loading()
        const payload = {
          firstPeriod: this.firstPeriod,
          lastPeriod: this.lastPeriod,
          filterBy: this.filterBy,
          students: this.students
        }
        const response = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/remessa/generate/lote`,
          payload
        )
        this.remessas = []
        response.data.forEach(element => {
          this.remessas.push({
            bank: element.bank,
            remessa: element.remessa,
            filename: element.filename
          })
        })

        if (this.remessas.length > 1) {
          this.remessaPopup = true
        } else {
          this.download(0)
        }

        this.$nextTick(() => {
          this.$vs.loading.close()
        })

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Operação realizada com sucesso.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    checkPeriod () {
      if (
        moment(this.firstPeriod).year() > 1000 &&
        moment(this.lastPeriod).year() > 1000
      ) {
        const difDays = moment(this.lastPeriod).diff(
          moment(this.firstPeriod),
          'days'
        )
        if (difDays < 0) {
          this.periodError = true
          this.periodErrorMessage =
            'O período inicial deve ser anterior ao final.'
          return
        } else if (difDays > 365 * 5 + 1) {
          this.periodError = true
          this.periodErrorMessage = 'O período não pode ser maior que 5 anos.'
          return
        }
        this.periodError = false
      } else if (!this.firstPeriod && !this.lastPeriod) {
        this.periodError = true
        this.periodErrorMessage = 'O período é obrigatório.'
      }
    },

    clearSelectedStudents () {
      this.students.splice(0)
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: 'STUDENT'
          })
          .then(
            function (response) {
              this.studentOptions = []
              for (const k in response.data) {
                //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
                const idx = this.studentOptions.findIndex(
                  (e) => e.value === response.data[k].id
                )
                if (idx === -1) {
                  this.studentOptions.push({
                    value: response.data[k].id,
                    label: `${response.data[k].name} ${
                      response.data[k].cpf || ''
                    }`
                  })
                }
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    }
  },
  mounted () {
    this.firstPeriod = this.today
    this.lastPeriod = this.today
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
  }
}
</script>

<style>
</style>
